html,body {
  width: 100%;
  /* height: 100%; */
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  /* font-family: Roboto, serif !important; */
  max-width: 1400px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer{
  background-color: #133a6b;
  color: white
}

.jumbo-banner{
  /* background-image: url('./images/bridge.jpg'); */
 background-size: cover !important;
 height: 350px;
 background-position: center center!important;
  background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2) ), url('./images/farmTree.jpg');
  color: white;
}

.banner-container h1{
  font-size: 4em;
}

#growth{
  overflow-y: scroll;
}

.btn-primary{
  border-color: none !important;
}
.btn-primary:hover{
  background-color: #e77725 !important;
}
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.hvr-wobble-vertical {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-wobble-vertical:hover, .hvr-wobble-vertical:focus, .hvr-wobble-vertical:active {
  -webkit-animation-name: hvr-wobble-vertical;
  animation-name: hvr-wobble-vertical;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.btn-outline-warning {
  color: #e77725 !important;
  border-color: #e77725 !important;
}

.btn-outline-warning:hover{
  background-color: #e77725 !important;
  color: #133a6b !important;
}

.accordion>.card>.card-header{
  background-color: rgba(0,0,0,.1);
}

.card-header:first-child{
  background-color: transparent;
}


/* screen size adjustments */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 1000px){
.card-columns {
    column-count: 1 !important;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
}
}
@media (max-height: 700px){
  
  .behind-cover-logo{
    width: 45% !important;
  }
}

@media(max-height: 585px){
  .behind-cover-logo{
    width: 30% !important;
  }
}

@media(max-height: 460px){
  .behind-cover-logo{
    width: 20%;
  }
}
@media (max-width: 400px) {
  .banner-container {
   height: 50vh !important;
  }
  .behind-cover-logo{
    width: 80% !important;
    background: rgba(255, 255, 255, .8);

  }
}
@media(max-width: 1000px){
  .behind-cover-logo{
    width: 80% !important;
    background: rgba(255, 255, 255, .8);

  };
}
@media (min-width: 300px){
  .justify-content-md-center {
      justify-content: center!important;
  }
  
  .process-img{
    width: 90%;
    height: auto !important;
    margin-bottom: 10px;
  }
   }

   @media only screen and (max-width: 770px) {
     .bio-img{
      padding-bottom: 20px;
    }
  }
  /* @media only screen and (min-width: 770px) {
    .bio-img{
      margin-right: -10px !important;
    }
  } */




   /* ======================== */


   /* Navbar */

   .navbar-nav{
    margin-left: auto;
  }
  .home-nav{
  background-color: transparent !important;
  z-index: 999 !important;
  width: 100vw !important;
  max-width: 1400px;
  margin: auto;
  
  }
  .home-nav a:hover{
  color: #e77725 !important;
  }
  
  .scroll{
    background-color: #133a6b !important;
    box-shadow: 0px 2px 5px black
  
    /* position: fixed; */
  }
  
  .scroll .login-btn{
  margin-bottom: 10px;
  }
  
  .home-nav-container{
    position: absolute;
    top: 0;
    left: 0;
    /* max-width: 100vw !important; */
    /* max-width: 1400px; */
  
  }
  
  .nav-item{
    padding-right: 10px;
    color: white !important;
  }
  
  .nav-item > a{
    color: white;
    font-size: 150%;
  }
  
  .navbar{
    padding: 0rem 1rem !important;
  }

  .navbar:hover{
    background-color: #133a6b!important;
  }

  .nav-logo{
    background-color: white;
    opacity: .4;
    
  }
  
  .nav-logo:hover{
    opacity: .8;
  }

  .navbar-light .navbar-toggler{
    border-color: #e77725 !important;
  }

  #basic-nav-dropdown{
    color: white;
    font-size: 150%;
    padding-top: 0px;
  }
  

  .banner-logo{
    border-radius: 5px;
    width: 80px;
    align-items: center;
    justify-content: center;
    padding-top: 7px;
    margin-right: 10px;
    
  }

  .behind-transparent-logo{
    background-color: white;
    background: rgba(255, 255, 255, 0.3);
    width: 125px;
    height: 75px;
    border-radius: 25px;
  }
  
  .behind-transparent-logo:hover{
    background: rgba(255, 255, 255, .8);
  
  }

  .nav-logo-sybmol{
    border-radius: 50% !important;
    width: 50px !important;
  }
  

  /* ================================== */

  /* Home page cover section */

  .banner-container{
    background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2) ), url("./images/ozarksBanner.jpg");
    height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   background-size: cover; 
  color: white;
   margin: 0px;
   padding: 0px;
   min-height: 400px;
   /* max-width: 1400px; */
   /* margin: auto; */
  }

  .header-logo{
    border-radius: 5px;
    width: 65%;
    height: auto;
    align-items: center;
    justify-content: center;
    padding-top: 12px;
  }

  .behind-cover-logo{
    border-radius: 25px;
    margin-top: 5%;
    margin-bottom: 10px;
    width: 60%;
    
    height: auto;
    transition: background-color .5s linear;
  }
  
  .behind-cover-logo:hover{
    background: rgba(255, 255, 255, .8);
  }
  
  /* =================================== */




/* Hang effect*/
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-hang:hover, .hvr-hang:focus, .hvr-hang:active {
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

/* =================================== */


/* home about section */

.home-about{
  background-color: #133a6b;
    color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: left;
  font-size: large;

}
.home-about h3{
  text-align: center;
}

.home-about a{
  color: white  !important;
}

.home-about p{
  text-align: center;
}



/* ================== */

/* home speacialties sections */
/* .speacialties{
  padding-left: 10px;
  padding-right: 50px;
} */

.speacialties .btn{
  background-color: #133a6b;
  width: 70%;
}
.speacialties .btn:hover{
  background-color: #e77725;
}

/* =================== */



/* Broker check transparent logo */

.brokercheck{
  position: fixed;
  bottom: 0;
  right: 0;
 
  opacity: .5;
  max-width: 200px;
}
.brokercheck:hover{
  opacity: 1;
 
}

.broker-check-logo{
  float: right;
  padding-right: 20px;
  padding-bottom: 20px;

}

/* ========================= */


/* process section */


.process p,h3{
  text-align: left;
}

.process-contact{
  font-size: larger;
}

.process-contact .btn-primary{
  background-color: #133a6b;
  color: white;
  border: none;

}

/* =========================== */




.about-bio a{
  color: black;
}

.about-bio h3{
  text-align: center;
  padding-top: 10px;
}

.dropdown-item:hover{
  color: #e77725 !important;
  border-bottom: #133a6b !important;
}